import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";

const locations = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    locations: [],
  },
  mutations: {
    ...tableMutations,
    SET_LOCATIONS(state, data) {
      const index = state.locations.findIndex((item) =>  { return item.virtualStockId === data.virtualStockId })
      if (index > -1) {
        state.locations[index].locations = data.locations;
      } else {
        state.locations.push(data);
      }
    },
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, rootState }) {
      commit("SET_ERROR", false);
      commit("SET_LOADING", true);

      const sku = rootState.skusOverview.sku.sku;
      const virtualStockId = rootState.skusOverview.virtualStockDetails.virtualStock['id'];

      const params = {
        search: [{ column: "sku", value: sku }]
      };
        InventoryService.virtualStocks.getSubResource(virtualStockId, 'stock', params)
        .then(json => {
          if (json) {
            commit("SET_LOCATIONS", { virtualStockId: virtualStockId, locations: json.items });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });

    }
  },
  getters: {
    ...tableGetters,
    locationsByVirtual: (state) => (virtualStockId) => {
      const entry = state.locations.find(item => item.virtualStockId === virtualStockId);
      if (entry && entry.locations) {
        return entry.locations
          .filter(item => item.virtualIncludedLocation.virtualLocationType !== 'exclude')
          .filter(item => item.stock.qty !== 0);
      }
      return [];
    },
    locationsTotalByVirtual: (state) => (virtualStockId) => {
      const entry = state.locations.find(item => item.virtualStockId === virtualStockId)

      if (entry && entry.locations) {
        return entry.locations.map((location) => {
          if (location.virtualIncludedLocation.virtualLocationType ==='exclude') {
            return 0;
          }

          if (location.virtualIncludedLocation.positive === false) {
            return location.stock.qty * -1
          } else {
            return location.stock.qty
          }
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue)
      }

      return 0;
    },
  }
}


export default locations;