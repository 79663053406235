import createClient from "@tt/vue-components/src/services/apiClient";
import apiServiceHelpers from "@tt/vue-components/src/services/apiServiceHelpers";
import EnvProvider from "@tt/vue-environment/EnvProvider";
import baseApiService from "@tt/vue-components/src/services/baseApiService";
const apiClient = createClient(EnvProvider.value("INVENTORY_API_URL"), EnvProvider.value("INVENTORY_API_VERSION"));

const InventoryService = {
  blacklist: {
    ...baseApiService(apiClient, 'blacklist_skus')
  },
  locations: {
    ...baseApiService(apiClient, 'locations'),
  },
  locationTree: {
    get: async (config) => {
      return await apiClient.get('location_tree' + apiServiceHelpers.buildQueryParams(config), {
        headers: {
          "Accept": "application/ld+json"
        }
      })
    },
  },
  reservationGroups: {
    ...baseApiService(apiClient, 'reservation_groups')
  },
  reservationItems: {
    ...baseApiService(apiClient, 'reservation_items')
  },
  ruleLinks: {
    ...baseApiService(apiClient, 'rule_links')
  },
  reservations: {
    ...baseApiService(apiClient, 'reservations')
  },
  rules: {
    ...baseApiService(apiClient, 'rules')
  },
  skus: {
    ...baseApiService(apiClient, 'skus'),
    calculate: async (data) => {
      return await apiClient.put("skus/calculate", data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    },
    backorders: async (data) => {
      return await apiClient.put("skus/backorders", data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
    },
  },
  virtualStocks: {
    ...baseApiService(apiClient, 'virtual_stocks'),
    absolute: async (config) => {
      return await apiClient.get("stock/absolute" + apiServiceHelpers.buildQueryParams(config))
    },
    delta: async (config) => {
      return await apiClient.get("stock/delta" + apiServiceHelpers.buildQueryParams(config))
    },
    live: async (config) => {
      return await apiClient.get("stock/live" + apiServiceHelpers.buildQueryParams(config))
    },
    syncVirtualStock: async (task) => {
      return await apiClient.post("virtual_stocks:sync_virtual_stock", task)
    },
  },
  virtualStockSyncAdapters: {
    ...baseApiService(apiClient, 'virtual_stock_sync_adapters'),
  },
  virtualStockSyncTargets: {
    ...baseApiService(apiClient, 'virtual_stock_sync_targets'),
  },
  stockAllocationItems: {
    ...baseApiService(apiClient, 'stock_allocation_items')
  },
  stockMutations: {
    ...baseApiService(apiClient, 'stock_mutations'),
    statistics: async (config) =>{
      return await apiClient.get("stock_mutations/statistics" + apiServiceHelpers.buildQueryParams(config))
    }
  },
  stock: {
    ...baseApiService(apiClient, 'stocks')
  },
  virtualIncludedLocations: {
    ...baseApiService(apiClient, 'virtual_included_locations')
  },
  virtualLocations: {
    ...baseApiService(apiClient, 'virtual_locations')
  },
  virtualStockSyncs: {
    ...baseApiService(apiClient, 'virtual_stock_syncs')
  },
  virtuals: {
    ...baseApiService(apiClient, 'virtuals'),
    recalculateStock: async (task) => {
      return await apiClient.post("virtuals:recalculate_virtual_stock", task)
    },
  },
  taskStatus: {
    ...baseApiService(apiClient, 'task_statuses')
  },
  tasks: {
    ...baseApiService(apiClient, 'tasks')
  },
  generic: {
    delete: async (iri) => {
      return await apiClient.delete(iri)
    },
    update: async (iri, data) => {
      return await apiClient.put(iri, data)
    },
    create: async (iri, data) => {
      return await apiClient.post(iri, data)
    },
  },
  apiClient: {
    apiClient
  }
}
export default InventoryService;
