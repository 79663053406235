import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import skuFilter from "@/store/modules/stockMutations/filters/skuFilter";
import locationsFilter from "@/store/modules/stockMutations/filters/locationsFilter";
import locationCodeOrNameFilter from "@/store/modules/stockMutations/filters/locationCodeOrNameFilter";
import isProcessedFilter from "@/store/modules/stockMutations/filters/isProcessedFilter";

const stockMutationsOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    skuFilter,
    locationsFilter,
    isProcessedFilter,
    locationCodeOrNameFilter
  },
  state: {
    ...tableState,
    sku: null,
  },
  mutations: {
    ...tableMutations,
    SET_SKU(state, sku) {
      state.sku = sku;
      state.search = [{ column: "sku", value: sku.sku }];
    }
  },
  actions: {
    ...tableActions,
    setSku({ commit }, sku) {
      commit("SET_SKU", sku);
    },
    fetchItems({commit, state}, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.stockMutations.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    }
  },
  getters: {
    ...tableGetters
  }
};

export default stockMutationsOverview;
