const skuFilter = {
  namespaced: true,
  state: {
    data: ""
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    setData(context, value) {
      context.commit("SET_DATA", value);
    }
  },
  getters: {
    data: state => {
      return state.data;
    },
    toParams: state => {
      return [
        {column: "reservationItems.sku", value: state.data},
      ];
    }
  }
};
export default skuFilter;
