import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import inventoryService from "@/services/InventoryService";

const virtualLocationsTree = {
  requestController1: null,
  requestController2: null,
  requestController3: null,
  requestController4: null,
  namespaced: true,
  modules: {
  },
  state: {
    ...tableState,
    virtual: null,
    groups: [],
    locations: [],
    virtualIncludedLocations: [],
    virtualLocations: [],
    tree: [],
  },
  mutations: {
    ...tableMutations,
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_VIRTUAL(state, virtual) {
      state.virtual = virtual;
    },
    SET_TREE(state, tree) {
      state.tree = tree;
    },
    SET_NODE(state, node) {
      state.node = node;
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
    SET_VIRTUAL_INCLUDED_LOCATIONS(state, locations) {
      state.virtualIncludedLocations = locations;
    },
    SET_VIRTUAL_LOCATIONS(state, locations) {
      state.virtualLocations = locations;
    },
  },
  actions: {
    ...tableActions,
    fetchVirtualIncludedLocations({ state, commit }, refresh) {
      if (refresh || state.locations.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: { page: 1, itemsPerPage: 1000 },
          sorting: state.sorting,
          search: [{column: 'virtual.code', value: state.virtual.code}]
        };

        inventoryService.virtualIncludedLocations.list(params)
          .then(json => {
            if (json) {
              commit("SET_VIRTUAL_INCLUDED_LOCATIONS", json.items);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    fetchVirtualLocations({ state, commit }, refresh) {
      if (refresh || state.locations.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: { page: 1, itemsPerPage: 1000 },
          sorting: state.sorting,
          search: [{column: 'virtual.code', value: state.virtual.code}]
        };

        if (this.requestController2) {
          this.requestController2.abort();
        }
        this.requestController2 = new AbortController();

        inventoryService.virtualLocations.list(params, { signal: this.requestController2.signal })
          .then(json => {
            if (json) {
              commit("SET_VIRTUAL_LOCATIONS", json.items);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    fetchLocations({ state, commit }, refresh) {
      if (refresh || state.locations.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: { page: 1, itemsPerPage: 1000 },
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController3) {
          this.requestController3.abort();
        }
        this.requestController3 = new AbortController();

        inventoryService.locations.list(params, { signal: this.requestController3.signal })
          .then(json => {
            if (json) {
              commit("SET_LOCATIONS", json.items);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    loadTree({ rootState, commit }) {
      const virtual = rootState.virtualsOverview?.virtual;
      if (virtual) {
        commit("SET_VIRTUAL", virtual);

        if (this.requestController4) {
          this.requestController4.abort();
        }
        this.requestController4 = new AbortController();

        inventoryService.virtuals.getSubResource(virtual.id, "virtual_location_tree", null, { signal: this.requestController4.signal })
          .then(json => {
            if (json) {
              commit("SET_TREE", json["items"][0]);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    add({ commit, dispatch }, virtualLocation) {
      return new Promise((resolve, reject) => {
        inventoryService.virtualLocations.create(virtualLocation)
          .then(() => {
            dispatch("loadTree", true);
            commit("SET_NODE", null);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    edit({ commit, dispatch }, virtualLocation) {
      // Update location through API
      return new Promise((resolve, reject) => {
        inventoryService.virtualLocations.update(virtualLocation.id, virtualLocation)
          .then(() => {
            dispatch("loadTree", true);
            commit("SET_NODE", null);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    delete({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        inventoryService.virtualLocations.delete(state.node.virtualLocation.id)
          .then(() => {
            dispatch("loadTree", true);
            commit("SET_NODE", null);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    setNode({ commit }, node) {
      commit("SET_NODE", node);
    },

  },
  getters: {
    ...tableGetters,
    locationGroups: state => {
      if (state.loading === false && state.locations.length) {
        return state.locations
          .filter((location) =>  {
            return location.section === true &&
              (
                (location.code !== state.node?.location.code) &&
                (location.code !== state.node?.location.parent?.location.code)
              )
          })
          .sort((locationA, locationB) => {
            if (locationA.code === "root") {
              return -1;
            }

            if (locationB.code === "root") {
              return 1;
            }

            return 0;
          })
      } else {
        return [];
      }
    },
    renderTree: (state, getters, rootState) => {
      let tree = [];


      let createChild = (node, parent) => {
        let locationType = 'location';

        if (node.location.section === true) {
          locationType = 'group';
        }

        let includeType = 'included';
        if (node.virtualIncludedLocation) {
          includeType = node.virtualIncludedLocation.virtualLocationType;
        }
        if (!node.virtualLocation && !node.virtualIncludedLocation) {
          includeType = "auto-include"
        }

        let item = {
          info: null,
          location: node.location,
          virtualLocation: node.virtualLocation,
          virtualIncludedLocation: node.virtualIncludedLocation,
          children: [],
          class: [includeType, locationType].join(' '),
          state: includeType,
          type: locationType,
          parent: (parent) ? parent.location : null,
          virtual: rootState.virtualsOverview?.virtual,
        };

        if (item.type === 'group') {
          item.info = {
            total: node.total,
            linked: node.totalLinked,
          }
        }

        if (node.children) {
          for (let x = 0; x < node.children.length; x++) {
            const child = createChild(
              node.children[x],
              node
            );

            // Only add it we have valid child returned, otherwise skip the node.
            if (child) {
              item.children.push(child);
            }
          }
        }
        return item;
      }

      if (state.tree) {
        // add only items children
        let root = state.tree;
        if (root.location) {
          for (let x = 0; x < root.children.length; x++) {
            const child = createChild(root.children[x], root);
            if (child) {
              tree.push(child);
            }
          }
        }
      }

      return tree;
    },
    locationsFiltered: (state) => {
      const existingLocations = state.virtualLocations?.map(item => item.location.code);
      const existingIncludedLocations = state.virtualIncludedLocations?.map(item => item.location.code);
      if (!existingLocations && !existingIncludedLocations) {
        return [];
      }
      return state.locations.filter(item => (!existingLocations.includes(item.code) && !existingIncludedLocations.includes(item.code)) && item.section === false);
    },
    locationGroupsFiltered: (state) => {
      const existingLocations = state.virtualLocations?.map(item => item.location.code);
      const existingIncludedLocations = state.virtualIncludedLocations?.map(item => item.location.code);
      if (!existingLocations && !existingIncludedLocations) {
        return [];
      }
      return state.locations.filter(item => (!existingLocations.includes(item.code) && !existingIncludedLocations.includes(item.code)) && item.section === true);
    },
  }
};

export default virtualLocationsTree;
