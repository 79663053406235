import Vue from "vue";
import TtApi from "@tt/tt-api";
import EnvProvider from "@tt/vue-environment/EnvProvider";

let domain = EnvProvider.value("AUTH0_DOMAIN");
let clientId = EnvProvider.value("AUTH0_CLIENT_ID");
let audience = EnvProvider.value("AUTH0_AUDIENCE");
let organization = EnvProvider.value("AUTH0_ORGANIZATION");
let inventoryApiUrl = EnvProvider.value("INVENTORY_API_URL");
let entityApiUrl = EnvProvider.value("ENTITY_API_URL");

const clientConfig = {
  enableAuth: true,
  defaultLanguage: "nl",
  api: {
    entity: {
      url: entityApiUrl
    },
    tasks: {
      url: inventoryApiUrl
    },
    inventory: {
      url: inventoryApiUrl
    }
  },
  auth0: {
    domain: domain,
    clientId: clientId,
    audience: audience,
    organization: organization,
    scope: "client_credentials"
  }
}

Vue.use(TtApi, clientConfig)
