import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import ruleLinks from "@/store/modules/virtuals/rules";
import InventoryService from "@/services/InventoryService";

const virtualLocations = {
  requestController: null,
  namespaced: true,
  modules: {
    ruleLinks,
  },
  state: {
    ...tableState,
    virtual: null,
  },
  mutations: {
    ...tableMutations,
    SET_VIRTUAL_LOCATION(state, location) {
      state.virtual_included_location = location;
    },
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state, rootState }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        const virtual = rootState.virtualsOverview.virtual;
        params.search = [
          { column: "virtual.code", value: virtual.code },
          { column: "location.section", value: true },
          { column: "virtualLocationType", value: "include" },
        ];

        params.sorting = { sortBy: ["location.code"], sortDesc: [false] }

        InventoryService.virtualLocations.list(params)
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    selectVirtualLocation({ commit }, item) {
      if (item !== null) {
        commit("SET_VIRTUAL_LOCATION", item);
        commit("SET_ITEM", item);
      } else {
        commit("SET_VIRTUAL_LOCATION", null);
        commit("SET_ITEM", null);
      }
    },
  },
  getters: {
    ...tableGetters
  }
};

export default virtualLocations;
