import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";

const virtuals = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchVirtuals({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: { itemsPerPage: 99999, itemsPage: 1 },
          sorting: { sortBy: ["name"], sortDesc: [false] }
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.virtuals.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
    filteredVirtuals: (state, getters, rootState) => {
      let adapter = rootState.adaptersOverview.adapter;
      if (adapter && adapter.virtuals.length) {
        let virtualIds = adapter.virtuals.map(virtual => virtual.id);
        return state.items.filter(item =>
          !virtualIds.includes(item.id)
        );
      } else {
        return state.items;
      }}
  }
}


export default virtuals;