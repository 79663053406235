const customFieldFilter = {
    namespaced: true,
    state: {
        data: { 1: null, 2: null, 3: null, 4: null, 5:  null, 6: null }
    },
    mutations: {
        SET_DATA(state, data) {
            state.data[data.num.toString()] = data.data;
        }
    },
    actions: {
        setData(context, value) {
            context.commit("SET_DATA", value);
        }
    },
    getters: {
        data: state => {
            return state.data;
        },
        toParams: state => {
            let params = [];
            Object.keys(state.data).forEach( index => {
                if (state.data[index]) {
                    params.push({ column: "custom" + index, value: state.data[index] });
                }
            })
            return params;
        }
    }
};

export default customFieldFilter;
