import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import inventoryService from "@/services/InventoryService";

const rules = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    ruleLink: null,
    virtual: null,
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
  },
  mutations: {
    ...tableMutations,
    SET_RULE_LINK(state, ruleLink) {
      state.ruleLink = ruleLink;
    },
  },
  actions: {
    ...tableActions,
    setRuleLink(context, ruleLink) {
      context.commit("SET_RULE_LINK", ruleLink);
    },
    fetchItems(context, refresh = false) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_ERROR", false);
        context.commit("SET_LOADING", true);
        const params = { pagination: context.state.pagination, sorting: context.state.sorting };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        inventoryService.virtuals.getSubResource(context.rootState.virtualsOverview.virtual.code, 'rule_links', params,  { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              context.commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            context.commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },

    editRuleLink(context, ruleLink) {
      return new Promise((resolve, reject) => {
        inventoryService.ruleLinks.update(ruleLink.id, ruleLink)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    addRuleLink(context, ruleLink) {
      return new Promise((resolve, reject) => {
        inventoryService.ruleLinks.create(ruleLink)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    deleteRuleLink(context, ruleLink) {
      return new Promise((resolve, reject) => {
        inventoryService.ruleLinks.delete(ruleLink.id)
          .then(() => {
            context.dispatch("fetchItems", true);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default rules;
