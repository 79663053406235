import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import locations from "@/store/modules/skus/virtualStockDetails/locations";

const virtualStockDetails = {
  requestController: null,
  namespaced: true,
  modules: {
    locations,
  },
  state: {
    ...tableState,
    virtualStock: null,
  },
  mutations: {
    ...tableMutations,
    SET_VIRTUAL_STOCK(state, virtualStock) {
      state.virtualStock = virtualStock;
    }
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state, rootState }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const sku = rootState.skusOverview.sku.sku;

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search,
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.skus.getSubResourceAlt('virtual_stocks', sku, params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    setVirtualStock({ commit }, virtualStock) {
      commit("SET_VIRTUAL_STOCK", virtualStock)
    }
  },
  getters: {
    ...tableGetters,
  }
}

export default virtualStockDetails;
