import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";

const adapters = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchAdapters({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);
        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        params.sorting = { sortBy: ["name"], sortDesc: [false] }
        params.pagination = { page: 1, itemsPerPage: 999 }

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.virtualStockSyncTargets.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
    filteredAdapters: (state, getters, rootState) => {
      let virtual = rootState.virtualsOverview.virtual;
      if (virtual && virtual.syncTargets.length) {
        let targetIds = virtual.syncTargets.map(target => target.id);
        return state.items.filter(item =>
          !targetIds.includes(item.id)
        );
      } else {
        return state.items;
      }}
  }
};

export default adapters;
