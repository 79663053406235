import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import inventoryService from "@/services/InventoryService";
import entityLocations from "@/store/modules/inventoryLocations/entityLocations";

const inventoryLocationsOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    entityLocations
  },
  state: {
    ...tableState,
    loading: false,
    error: false,
    tree: null,
    node: null,
    locations: [],
  },
  mutations: {
    ...tableMutations,
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_TREE(state, tree) {
      state.tree = tree;
    },
    SET_NODE(state, node) {
      state.node = node;
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations;
    },
  },
  actions: {
    ...tableActions,
    fetchTreeItems({ commit, state }, refresh = false) {
      if (refresh || state.tree.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        inventoryService.locationTree.get()
          .then(json => {
            if (json) {
              commit("SET_TREE", json["items"][0], { signal: this.requestController.signal });
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    add({ commit, state }, location) {
      return new Promise((resolve, reject) => {
        inventoryService.locations.create(location)
          .then((newLocation) => {

            // Create a new node
            let updatedNode = {
              location: newLocation,
              children: [],
              virtual:  [],
              parent:   location.parentLocation
            }

            // Update the location tree
            const updateNode = (treeNode) => {
              // Are we at the right level? Add it!
              if (treeNode.location['@id'] === updatedNode.parent) {
                treeNode.children.push(updatedNode);
              }

              // Otherwise we keep looking.
              if (treeNode.children.length > 0) {
                for (let i = 0; i < treeNode.children.length; i++) {
                  treeNode.children[i] = updateNode(treeNode.children[i]);
                }
              }

              return treeNode;
            }

            let tree = state.tree;
            const children = [];
            if (tree.location["@id"] === updatedNode.parent) {
              tree.children.push(updatedNode);
            } else {
              for (let i = 0; i < tree.children.length; i++) {
                children.splice(i, 1);
                children[i] = updateNode(tree.children[i]);
              }
              tree.children = children;
            }

            commit("SET_TREE", tree);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    edit({ commit, state }, location) {
      // Update location through API
      return new Promise((resolve, reject) => {
        inventoryService.locations.update(location.code, location)
          .then(() => {
            // Mutate te node.location:
            state.node.location.name = location.name;
            state.node.location.nameAndCode = `${location.name} (${location.code})`;
            state.node.location.active = location.active;

            // Create a new node based on the old one with the mutations
            let updatedNode = {
              location: state.node.location,
              children: state.node.children,
              virtual:  state.node.virtuals,
              parent:   state.node.parent
            }

            commit("SET_NODE", updatedNode);

            // Update the location tree
            const deleteNode = (treeNode) => {
              // Delete old location if found
              for (let j = 0; j <= treeNode.children.length; j++) {
                if (treeNode.children[j]?.location.code === updatedNode.location.code) {
                  treeNode.children.splice(j, 1);
                  return treeNode
                }
              }

              // Otherwise we keep looking.
              if (treeNode.children.length > 0) {
                for (let i = 0; i < treeNode.children.length; i++) {
                  treeNode.children[i] = deleteNode(treeNode.children[i]);
                }
              }

              return treeNode;
            }

            const addNode = (treeNode) => {
              // Are we at the right level? Add it!
              if (treeNode.location.code === updatedNode.parent.code) {
                treeNode.children.push(updatedNode);
                return treeNode;
              }

              // Otherwise we keep looking.
              if (treeNode.children.length > 0) {
                for (let i = 0; i < treeNode.children.length; i++) {
                  treeNode.children[i] = addNode(treeNode.children[i]);
                }
              }

              return treeNode;
            }

            let tree = state.tree;
            // First delete the node
            for (let i = 0; i < tree.children.length; i++) {
              if (tree.children[i].location.code === updatedNode.location.code) {
                tree.children.splice(i, 1);
                break;
              } else {
                tree.children[i] = deleteNode(tree.children[i]);
              }
            }

            // Then add it
            if (tree.location.code === updatedNode.parent.code) {
              tree.children.push(updatedNode);
            } else {
              for (let i = 0; i < tree.children.length; i++) {
                tree.children[i] = addNode(tree.children[i]);
              }
            }

            commit("SET_TREE", tree);

            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    delete({ commit, state }) {
      return new Promise((resolve, reject) => {
        const deletedCode = state.node.location.code;
        inventoryService.locations.delete(state.node.location.code)
          .then(() => {
            const updateNode = (treeNode) => {
              // Delete old location if found
              for (let j = 0; j <= treeNode.children.length; j++) {
                if (treeNode.children[j]?.location.code === deletedCode) {
                  treeNode.children.splice(j, 1);
                }
              }

              // Otherwise we keep looking.
              if (treeNode.children.length > 0) {
                for (let i = 0; i < treeNode.children.length; i++) {
                  treeNode.children[i] = updateNode(treeNode.children[i]);
                }
              }

              return treeNode;
            }

            let tree = state.tree;
            const children = [];

            for (let i = 0; i < tree.children.length; i++) {
              if (tree.children[i]?.location.code === deletedCode) {
                tree.children.splice(i, 1);
                break;
              }
              children[i] = updateNode(tree.children[i]);
            }

            tree.children = children;
            commit("SET_TREE", tree);
            commit("SET_NODE", null);
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    setNode({ commit }, node) {
      commit("SET_NODE", node);
    },
    fetchLocations({ state, commit }, refresh) {
      if (refresh || state.locations.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);
        const params = {
          pagination: { page: 1, itemsPerPage: 1000 },
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        inventoryService.locations.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_LOCATIONS", json.items);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
    locationGroups: state => {
      if (state.loading === false && state.locations.length) {
        return state.locations
          .filter((location) =>  {
            return location.section === true &&
              (
                (location.code !== state.node?.location.code) &&
                (location.code !== state.node?.location.parent?.location.code)
              )
          })
          .sort((locationA, locationB) => {
            if (locationA.code === "root") {
              return -1;
            }

            if (locationB.code === "root") {
              return 1;
            }

            return 0;
          })
      } else {
        return [];
      }
    },
    renderTree: state => {
      let tree = [];

      let prepChilds = (node, parent) => {
        let item = {
          location: node.location,
          children: [],
          class: null,
          active: (node.location) ? true : false,
          parent: (parent) ? parent.location : null,
          virtuals: node.virtuals,
          id: node.location.code,
          name: node.location.nameAndCode
        };

        if (node.children) {
          for (let x = 0; x < node.children.length; x++) {
            item.children.push(
              prepChilds(
                node.children[x],
                node
              )
            );
          }
        }

        return item;
      }

      if (state.tree) {
        // add only items children
        let root = state.tree;
        if (root) {
          // normalize
          for (let x = 0; x < root.children.length; x++) {
            tree.push(prepChilds(root.children[x], root));
          }
        }
      }
      return tree;
    }
  }
};

export default inventoryLocationsOverview;
