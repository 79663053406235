const targetFilter = {
  namespaced: true,
  state: {
    data: []
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    setData(context, data) {
      context.commit("SET_DATA", data);
    },
  },
  getters: {
    data: state => {
      return state.data;
    },
    toParams: state => {
      return state.data.map(option => {
        return { column: "target[]", value: option };
      });
    }
  }
};

export default targetFilter;
