import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import rules from "@/store/modules/virtuals/rules";
import adapters from "@/store/modules/virtuals/adapters";
import InventoryService from "@/services/InventoryService";
import virtualIncludedLocations from "@/store/modules/virtuals/virtualIncludedLocations";
import virtualLocationsTree from "@/store/modules/virtuals/virtualLocationsTree"
import virtualLocations from "@/store/modules/virtuals/virtualLocations";

const virtualsOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    rules,
    adapters,
    virtualIncludedLocations,
    virtualLocationsTree,
    virtualLocations
  },
  state: {
    ...tableState,
    virtual: null,
  },
  mutations: {
    ...tableMutations,
    SET_VIRTUAL(state, virtual) {
      state.virtual = virtual;
    },
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.virtuals.list(params)
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    selectVirtual({ commit }, item) {
      if (item !== null) {
        commit("SET_VIRTUAL", item);
        commit("SET_ITEM", item);
      } else {
        commit("SET_VIRTUAL", null);
        commit("SET_ITEM", null);
      }
    },
    edit({commit}, virtual) {
      return new Promise((resolve, reject) => {
        InventoryService.virtuals.update(virtual.id, virtual)
          .then((virtual) => {
            commit("SET_VIRTUAL", virtual);
            commit("EDIT_ITEM", virtual);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    add({commit}, virtual) {
      return new Promise((resolve, reject) => {
        InventoryService.virtuals.create(virtual)
          .then((data) => {
            commit("SET_VIRTUAL", data);
            commit("ADD_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    patch({commit}, virtual) {
      return new Promise((resolve, reject) => {
        InventoryService.virtuals.patch(virtual.id, virtual.body)
          .then((data) => {
            commit("SET_VIRTUAL", data);
            commit("SET_ITEM", data);
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    syncVirtualStock(_, task) {
      return new Promise((resolve, reject) => {
        InventoryService.virtualStocks.syncVirtualStock(task)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
    recalculateStock(_, task) {
      return new Promise((resolve, reject) => {
        InventoryService.virtuals.recalculateStock(task)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err["hydra:description"]);
          });
      });
    },
  },
  getters: {
    ...tableGetters
  }
};

export default virtualsOverview;
