import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import EntityService from "@/services/EntityService";

const entityLocations = {
  namespaced: true,
  state: {
    ...tableState,
    entityLocation: null
  },
  mutations: {
    ...tableMutations,
    SET_ENTITY_LOCATION(state, location) {
      state.entityLocation = location;
    }
  },
  actions: {
    ...tableActions,
    fetchLocation({ commit, rootState }, refresh = false) {
      if (refresh) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);
        commit("SET_ENTITY_LOCATION", null);
        const location = rootState.inventoryLocationsOverview.node.location;

        EntityService.locations.get(location.code)
          .then(json => {
            if (json) {
              commit("SET_ENTITY_LOCATION", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
  }
}


export default entityLocations;