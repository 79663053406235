import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";

const reservations = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState,
    sku: null,
    pagination: {itemsPerPage: 20, itemsPage: 1},
    footerProps: {"items-per-page-options": [10, 20, 40, 80]},
  },
  mutations: {
    ...tableMutations,
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state, rootState }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        const id = rootState.skusOverview.sku.sku;

        InventoryService.skus.getSubResourceAlt('reservation_items', id, params)
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
  },
  getters: {
    ...tableGetters,
    // filteredOnVirtual: (state, getters, rootState) => {
    //   let virtual = rootState.stockOverview.virtualsOverview.virtual;
    //   if (virtual) {
    //     let reservationGroups = virtual.reservationGroups.map(reservation =>
    //       reservation.replace("/reservation_groups/", "")
    //     );
    //     return state.items.filter(item =>
    //       reservationGroups.includes(item.reservationGroup.code)
    //     );
    //   } else {
    //     return [];
    //   }
    // }
  }
}


export default reservations;