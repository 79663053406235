import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";


const groups = {
  requestController: null,
  namespaced: true,
  state: {
    ...tableState
  },
  mutations: {
    ...tableMutations
  },
  actions: {
    ...tableActions,
    fetchItems({ state, commit }) {
      commit("SET_LOADING", true);

      const params = {
        pagination: { page: 1, itemsPerPage: 999 },
        sorting: state.sorting,
        search: state.search
      };

      InventoryService.reservationGroups.list(params)
        .then(json => {
          if (json) {
            commit("SET_ITEMS", json);
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
        commit("SET_LOADING", false);
      });
    }
  },
  getters: {
    ...tableGetters,
    optionList: state => {
      let options = [];
      state.items.forEach((group) => {
        options.push({
          text: group.name,
          value: group.code
        });
      });
      return options.sort((a, b) => a.text.localeCompare(b.text));
    }
  }
};

export default groups;
