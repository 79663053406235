import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sku",
    name: "sku",
    menuTitle: i18n.t("skus.title"),
    menuIcon: "mdi-information-outline",
    showIcon: false,
    component: () => import("@/views/SkusView"),
    active: true
  },
  {
    path: "/",
    redirect: "/sku",
    // name: "dashboard",
    // menuTitle: i18n.t("dashboard.title"),
    // menuIcon: "mdi-home-outline",
    // showIcon: false,
    // component: () => import("@/views/Dashboard"),
    showIcon: false,
    active: false
  },
  {
    path: "/sku/:sku",
    name: "sku/detail",
    component: () => import("@/views/SkusView"),
    active: true
  },
  {
    path: "/locations",
    name: "locations",
    menuTitle: i18n.t("locations.title"),
    menuIcon: "mdi-store",
    showIcon: false,
    component: () => import("@/views/LocationsView"),
    active: true
  },
  {
    path: "/virtual-stocks",
    name: "virtualStockOverview",
    menuTitle: i18n.t("virtuals.title"),
    menuIcon: "mdi-filter-outline",
    showIcon: false,
    component: () => import("@/views/VirtualsView"),
    active: true
  },
  {
    path: "/adapters",
    name: "adapters",
    menuTitle: i18n.t("adapters.title"),
    menuIcon: "mdi-database-sync",
    showIcon: false,
    component: () => import("@/views/AdaptersView"),
    active: true
  },
  {
    path: "/reservations",
    name: "reservations",
    menuTitle: i18n.t("reservations.title"),
    menuIcon: "mdi-calendar-lock",
    showIcon: false,
    component: () => import("@/views/ReservationsView"),
    active: true
  },
  {
    path: "/synchronisation",
    name: "synchronisation",
    menuTitle: i18n.t("synchronisation.title"),
    menuIcon: "mdi-sync",
    showIcon: false,
    component: () => import("@/views/SynchronisationLogView"),
    active: true
  },
  {
    path: "/stock-mutations",
    name: "mutations",
    menuTitle: i18n.t("mutations.title"),
    menuIcon: "mdi-database-sync",
    showIcon: false,
    component: () => import("@/views/StockMutationsView"),
    active: true
  },
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeResolve(authGuard);

export default router;
